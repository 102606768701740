<template>
  <fluency-loader v-if="areMetricsLoading" dots color class="m-0" />
  <date-range-picker v-else
                     :date-range="dateObj"
                     :opens="opens"
                     @update:dateRange="handleUpdate($event)"></date-range-picker>
</template>

<script>
import DateRangePicker from '~/components/common/dateSelector/components/DateRangePicker.vue'
import FluencyLoader from 'core-ui/components/common/fluencyLoader.vue'

export default {
  name: 'dateRangePickerGlobal',
  components: { FluencyLoader, DateRangePicker },
  props: {
    opens: String
  },
  computed: {
    dateObj () {
      const shortcut = this.$store.getters.timeRangeShortcut
      const dateRange = this.$store.getters.timeRange
      const compare = this.$store.getters.timeRangeCompare
      const compareOverride = this.$store.getters.timeRangeCompareOverride
      return {
        shortcut,
        start: dateRange[0],
        end: dateRange[1],
        compare,
        compareStart: compareOverride.start,
        compareEnd: compareOverride.end,
        compareStartOverride: compareOverride.startOverride,
        compareEndOverride: compareOverride.endOverride
      }
    },
    areMetricsLoading () {
      const isLoadingObj = this.$store.getters.loading
      return Object.keys(isLoadingObj).filter(key => key.endsWith('Metrics'))
        .some(key => isLoadingObj[key])
    }
  },
  methods: {
    handleUpdate (e) {
      this.$store.commit('timeRange', {
        shortcut: e.shortcut,
        custom: [e.start, e.end]
      })
      this.$store.commit('setTimeRangeCompare', e.compare)
      this.$store.commit('setTimeRangeCompareOverride', {
        start: e.compareStart,
        end: e.compareEnd,
        startOverride: e.compareStartOverride,
        endOverride: e.compareEndOverride
      })
      this.$emit('update')
    }
  }
}
</script>
